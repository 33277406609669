export default {
  DESKTOP: 992,
  TABLET: 768,
  PHONE: 376,
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};
