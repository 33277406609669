import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Container } from './nav.css';

export default class Nav extends React.Component {
  render() {
    return (
      <Container>
        <ul>
          <StaticQuery
            query={graphql`
              query NavQuery {
                allNodePage {
                  edges {
                    node {
                      title
                      path {
                        alias
                      }
                    }
                  }
                }
              }
            `}
            render={data =>
              data.allNodePage.edges.map(function(el, index) {
                return (
                  <li key={index}>
                    <Link to={el.node.path.alias}>{el.node.title}</Link>
                  </li>
                );
              })
            }
          />
        </ul>
      </Container>
    );
  }
}
